import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>25-900NB & 3-100 Wall, Tight & Long Radius Bends</p>
        <p>1.5D and greater centerline radius pipe bends and curves.</p>
        <p>
          Simple elbows, tightly contorted shapes, compound pipe spools to close
          dimensional tolerances, complex curves including spirals and ellipses;
          as well as three dimensional shapes such as helixes & free-form
          curvilinear to XYZ co-ordinates.
        </p>
        <p>
          For transmission pipelines, long radius bends can be formed with very
          little ovality or wall thinning for storage on site and cutting to the
          required angle as required for crossings tight terrain following bends
          to minimise excavation and environmental impact, and planned pipeline
          construction.
        </p>
        <p>
          Automated start/stop procedures have been developed specifically for X
          grades and and carbon steel pipes requiring accurate reproduction of
          qualification results, and can be programmed for optimum process
          control.
        </p>
      </div>
    </div>
  )
}

export default PageContent
